<template>
  <div ref="footerRef" class="footer-page">
    <PagesNewHomeCommonContainer>
      <div class="footer-content">
        <div class="logo-area">
          <!-- <CommonImage class="logo" :src="getCloudAssets('/images/logo-4.png')" alt="AI photo editor" :lazy="true" /> -->
          <div class="logo-wrapper">
            <CommonImage class="logo" :src="getCloudAssets('/images/common/footer-logo.png')" lazy />
            <CommonPMark v-if="isJp" />
          </div>
          <div class="trustpilot-link" @click="toTrustpilot">
            <span
              class="text"
              v-html="
                useTranslateI18n('trustpoilot.context', [
                  {
                    type: 'img',
                    class: 'trustpilot-img',
                    src: getCloudAssets('/images/pages/newHome/footer-trustpilot.png'),
                    alt: `AI photo editor`
                  }
                ])
              "
            />
          </div>
        </div>
        <div class="link-area">
          <div v-for="(cloItem, i) in RenderArr" :key="i" class="link-column-item">
            <div v-for="(item, index) in cloItem" :key="index" class="link-item">
              <div class="link-item-title">{{ item.title }}</div>
              <div class="content">
                <div v-for="(cItem, index) in item.children" :key="index" class="link-item-content">
                  <NuxtLink v-if="cItem.type === IClickType.Skip" :to="cItem.path">
                    <img v-if="cItem.imgPath" :src="cItem.imgPath" class="im1" />
                    <span>{{ cItem.name }}</span>
                  </NuxtLink>
                  <span v-if="cItem.type === IClickType.Click" class="link-t" @click="linkClickHandle(cItem)">
                    <img v-if="cItem.imgPath" :src="cItem.imgPath" class="im1" />
                    <span>
                      {{ cItem.name }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="info-area">
          <div v-if="showPte" class="left-t">
            {{ copyright }}
          </div>
          <div class="privacy-jp">
            <a v-if="isJp" class="left-t" target="_blank" :href="getCloudAssets('/policy/privacy-p-ja.html')">
              個人情報保護方針
            </a>
          </div>
          <div class="extend-link-item">
            <CommonSwitchLanguage />
          </div>
        </div>
        <div class="link-content">
          <div v-for="(item, i) in newHomeLinkBottomDict()" :key="i" class="extend-link-item">
            <a v-if="item.type === IClickType.Skip" :href="item.path" target="_blank">{{ item.name }}</a>
            <span v-if="item.type === IClickType.Click" @click="linkClickHandle(item)">
              <CommonImage class="icon" :src="item.imgPath" :lazy="true" />
            </span>
          </div>
        </div>
      </div>
    </PagesNewHomeCommonContainer>
  </div>
</template>

<script setup lang="ts">
import { ResizeSensor } from "css-element-queries"
import { IFooterLinkData, ILinkDataChildObj } from "@/types/components/common/footerLink"
import {
  LinkKey,
  NavLinkSM,
  linkKeyPointParams,
  NavLinkPointButtonNameDict,
  newHomeLinkBottomDict,
  newHomeLinkArrDict
} from "@/constant/footerLink"
import { IClickType } from "@/constant/index"
import { useCookieDialog } from "~~/composables/components/dialog/cookie/useCookieDialog"
import { pointPositionDict } from "@/composables/utils/gtag/download"
import { DownloadPlatform } from "@/constant/index"
import { DOWNLOAD_PAGE_PATH, PROFILE_PAGE_PATH, LANDING_REFERRAL } from "@/constant/route"
import { Breakpoints } from "~/constant/pages/newHome"
import { getHeadLanguage, ILocaleValue } from "~/constant/i18n-country"

let { useDownloadGTag, useFooterlinkGatg } = useGTag()
let { _PointOfficeDownloadMixin } = useDownloadGTag()
let { _PointOfficialWebsiteClick } = useFooterlinkGatg()

let { useCommonMp, useDownloadMp } = useMp()
let { _pointOfficialPageButtonClick } = useCommonMp()

let { _pointMpWebsiteDownloadApp } = useDownloadMp()

const locale = getHeadLanguage()

let { isJp } = useCountryJudge()

let showPte = ref(true)

const linkArr = ref<IFooterLinkData>(
  newHomeLinkArrDict().map((item, i) => {
    return {
      title: useTranslateI18n(`common.newHomeFooterLink[${i}].title`),
      children: item.children.map((cItem, index) => {
        return {
          name: useTranslateI18n(`common.newHomeFooterLink[${i}].children[${index}].name`),
          path: cItem.path,
          type: cItem.type,
          key: cItem.key,
          imgPath: cItem.imgPath
        }
      })
    }
  })
)

const linkClickHandle = useDebounceFn(async (item: ILinkDataChildObj) => {
  const { getPrivacy, getTerms } = useGetPolicy()
  let key = item.key
  // 联系我们点击
  if (key === LinkKey.ContactUs1) {
    window.location.href = `mailto:${item.name}` as string
  } else if (key === LinkKey.CookieSetting) {
    _pointOfficialPageButtonClick({
      buttonName: NavLinkPointButtonNameDict[key],
      path: useMpCommonPath(),
      pageTitle: useMpCommonPageTitle(),
      featureModule: "websiteButtonClick",
      featureName: "clickWebsiteBottomButton"
    })
    useCookieDialog()
  } else if (key === LinkKey.PrivacySetting) {
    getPrivacy()
  } else if (key === LinkKey.TermsSetting) {
    getTerms()
  } else if ([LinkKey.DownloadMac, LinkKey.DownloadWin].includes(key as any)) {
    _pointMpWebsiteDownloadApp({
      buttonName: key === LinkKey.DownloadMac ? "bottomDownloadMac" : "bottomDownloadWindows",
      path: useMpCommonPath(),
      pageTitle: useMpCommonPageTitle(),
      featureModule: "downloadButtonClick",
      featureName: "clickWebsiteDownloadButton"
    })
    let path = useRoute().path
    if (path !== unref(PROFILE_PAGE_PATH)) {
      _PointOfficeDownloadMixin({
        official_position: pointPositionDict[path],
        official_download_platform: key === LinkKey.DownloadMac ? DownloadPlatform.Mac : DownloadPlatform.Win
      })
    }
    navigateTo(unref(DOWNLOAD_PAGE_PATH))
  } else if (Object.keys(NavLinkSM).includes(key as any)) {
    _PointOfficialWebsiteClick({
      official_operate: linkKeyPointParams[key]
    })
    _pointOfficialPageButtonClick({
      buttonName: NavLinkPointButtonNameDict[key],
      path: useMpCommonPath(),
      pageTitle: useMpCommonPageTitle(),
      featureModule: "websiteButtonClick",
      featureName: "clickWebsiteBottomButton"
    })
    window.open(NavLinkSM[key], "_blank")
  } else if (key === LinkKey.ToPhone) {
    location.href = `tel://6596135281`
  } else if (key === LinkKey.ContactUsDialog) {
    useContactUsDialog()
    _pointOfficialPageButtonClick({
      path: useMpCommonPath(),
      pageTitle: useMpCommonPageTitle(),
      buttonName: "contactSales",
      featureModule: "websiteButtonClick",
      featureName: "clickWebsiteHomeWidget"
    })
  } else if (key === LinkKey.ReferralPage) {
    location.href = useReferrerConfigData().value.landing_link
  }
}, 300)

const RenderArr = ref([])
let column = 4

const getRenderArr = () => {
  const arr = Array.from({ length: column }, () => [])
  const length = linkArr.value.length
  for (let i = 0; i < length; i++) {
    arr[i % column].push(linkArr.value[i])
  }
  RenderArr.value = arr
}

const breakPointList = useBreakpoints(Breakpoints) as any

const toTrustpilot = () => {
  window.open("https://www.trustpilot.com/review/evoto.ai", "_blank")
}

const getResizeSensor = () => {
  nextTick(() => {
    if (breakPointList.$1920.value) {
      column = 4
    } else if (breakPointList.$1400.value) {
      column = 4
    } else if (breakPointList.$900.value) {
      column = 3
    } else if (breakPointList.$600.value) {
      column = 2
    } else {
      column = 2
    }
  })
  getRenderArr()
}
getResizeSensor()

const footerRef = ref()

onMounted(() => {
  new ResizeSensor(footerRef.value, () => {
    getResizeSensor()
  })
})

const copyright = computed(() => {
  const currentYear = new Date().getFullYear()
  const addresses = {
    [ILocaleValue.en]: `TRUESIGHT TECHNOLOGY INC.｜573 BELLEVUE ROAD SUITE D NEWARK, DE 19713`,
    default: `TRUESIGHT PTE.LTD.｜3 FRASER STREET #04-23A DUO TOWER SINGAPORE (189352)`
  }

  return `©️${currentYear} ${addresses[locale] || addresses.default}`
})
</script>

<style scoped lang="scss">
.footer-page {
  width: 100%;
  position: relative;
  background: #fff;
  .footer-content {
    box-sizing: border-box;
    padding: 80px 0;
    display: flex;
    flex-direction: row;
    .logo-area {
      flex-shrink: 0;
      gap: 40px;
      width: 230px;
      margin-right: 40px;
      .logo-wrapper {
        display: flex;
        .logo {
          margin-right: 24px;
        }
      }
      .logo {
        // width: 110px;
        // aspect-ratio: 241 / 80;
        width: 40px;
        aspect-ratio: 1;
        height: unset;
      }
      .trustpilot-link {
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        word-break: break-word;
        .text {
          color: #000;
          font-size: 16px;
          font-style: normal;
          line-height: normal;
          @include fontRobotoRegular;

          :deep(.trustpilot-img) {
            margin-left: 3px;
            width: 75px;
            aspect-ratio: 75 / 18;
            transform: translate(0, -2px);
          }
        }
      }
    }
    .link-area {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 16px;
      .link-column-item {
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex: 1;
        .link-item {
          display: flex;
          flex-direction: column;
          gap: 16px;
          .link-item-title {
            color: #000;
            font-size: 16px;
            font-style: normal;
            line-height: 150%;
            margin-bottom: 8px;
            @include fontRobotoMedium;
          }
          .content {
            display: flex;
            flex-direction: column;
            gap: 16px;
            .link-item-content {
              color: #000;
              font-size: 14px;
              font-style: normal;
              line-height: 150%;
              @include fontRobotoRegular;
              .link-t {
                cursor: pointer;
              }
              a {
                color: inherit;
              }
            }
          }
        }
      }
    }
  }
  .footer-bottom {
    width: 100%;
    border-top: 1px solid #000;
    padding: 32px 0 80px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px 0;
    .info-area {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 22px;
      .left-t {
        color: #000;
        font-size: 14px;
        font-style: normal;
        line-height: 150%; /* 21px */
        @include fontRobotoRegular;
      }
    }
    .link-content {
      display: flex;
      flex-direction: row;
      gap: 12px;
      .icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }
}
@include device-max-width-900 {
  .footer-page {
    .footer-content {
      padding: 48px 0;
      flex-direction: column;
      .logo-area {
        width: unset;
        .logo {
          width: 40px;
          height: 40px;
        }
      }
      .link-area {
        margin-top: 32px;
        gap: 16px;
        .link-column-item {
          gap: 40px;
          .link-item {
            gap: 16px;
            .link-item-title {
              font-size: 16px;
              margin-bottom: 8px;
            }
            .content {
              gap: 16px;
              .link-item-content {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    .footer-bottom {
      border-top: 1px solid #000;
      padding: 32px 0 80px 0;
      flex-direction: column;
      gap: 24px;

      .info-area {
        gap: 24px;
        flex-direction: column;
        align-items: flex-start;
        .left-t {
          font-size: 14px;
        }
      }
      .link-content {
        gap: 12px;
        .icon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
@include device-max-width-600 {
  .common-container {
    box-sizing: border-box;
    width: 100%;
    padding: 0 24px;
    margin: 0 0;
  }
}
</style>
